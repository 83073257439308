import { request as axios, request, ServiceReturn } from '@/util/request';
const clean = require('bmh/clean-empty-obj');
const getUrl = require('bmh/url-with-param');
async function getPda(type: any, current: number = 1) {

    const url = getUrl(`/boss/wms/staff`, {
        ...type,
        current
    });
    const res = await axios.get(url);
    return res
}
async function postPda(params: any) {
    try {
        const res = await request.post(`/boss/wms/staff`, params)
        return res
    } catch (e) {
        return {
            status: 500
        }
    }
}
async function putPda(params: any, id: number): ServiceReturn {
    try {
        const res = await request.put(`/boss/wms/staff/${id}`, params)
        return res
    } catch (e) {
        return {
            status: 500
        }
    }
}

async function putPdaStatus(params: any, id: number) {
    return request.put(`/boss/wms/staff/status/${id}`, params)
}
export {
    getPda,
    postPda,
    putPda,
    putPdaStatus
}